$(document).ready(function() {
    // Version selector
    $('.version-selector').on('change', function() {
        $('iframe').attr('src', $(this).val());
    });

    // Cookies
    window.cookieconsent.initialise({
        content: {
            message: 'By continuing to use this website, you consent to the use of cookies in accordance with our <a target="_blank" href="https://www.supportpal.com/company/privacy#cookies">Privacy Policy</a>.',
        },
        elements: {
            messagelink: '<span id="cookieconsent:desc" class="cc-message">{{message}}</span>',
        },
        palette: {
            popup: {
                background: "rgba(0, 0, 0, 0.9)",
                text: "#ccc"
            },
            button: {
                background: "#1B964F"
            },
        },
        theme: "edgeless",
        position: "bottom",
        type: "opt-out",
        revokable: false,
        onInitialise: function (status) {
            if (this.hasConsented()) {
                // Previously consented - Run google analytics
                googleAnalytics();
            }
        },
        onStatusChange: function (status, chosenBefore) {
            if (this.hasConsented()) {
                // Run google analytics
                googleAnalytics();
            }
        }
    });
});

window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}

/**
 * Google analytics tracking code.
 */
function googleAnalytics() {
    var ga = document.createElement('script');
    ga.type = 'text/javascript';
    ga.async = true;
    ga.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'www.googletagmanager.com/gtag/js?id=G-R5XWPWF3VD';

    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(ga, s);

    gtag('js', new Date());
    gtag('config', 'G-R5XWPWF3VD');
}